import * as React from "react"
import Seo from "../components/seo2023"
import FooterV2 from "../componentsv2/footerv2/footerv2"
import Layout from "../components/layout2023"
import HowItWorksV2 from "../componentsv2/how-it-worksv2/how-it-worksv2"
const HowItWorks = () => (
  <Layout nav={"hidden"} parentClass={`newtheme2024`}>
    <Seo title="How it Works" />
    <HowItWorksV2 />
    <FooterV2 version="v2" />
  </Layout>
)

export default HowItWorks
