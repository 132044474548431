export const slides = [
  {
    slideImage: "thumb-01.webp",
    companyLogo: "Okta_logo.png",
    personName: "Rodolfo Yiu",
    personPosition: "Sr. Manager of Digital Marketing",
    smallFeedback:
      "“Through a number of page redesign tests we’ve seen test lifts between 40-90%”",
    videoID: "xv3XXP_Tosg",
    videoLength: "6 min",
    logoAlt: "Okta",
    url: "/testimonials#okta",
  },
  {
    slideImage: "thumb-07.webp",
    smallFeedback:
      "“We saw a 125% increase in conversion rate for demo requests.”",
    personName: "Megan Gouveia",
    personPosition:
      "Sr. Manager of Digital Marketing, Personalization & Optimization",
    companyLogo: "Progress_logo.png",
    videoID: "oETkNoINKCQ",
    videoLength: "3 min",
    logoAlt: "Progress",
    url: "/testimonials#progress",
  },
  {
    slideImage: "thumb-09.webp",
    personName: "Pat Oldenburg",
    personPosition: "VP of Demand Marketing & Ops",
    smallFeedback:
      "“We had aggressive KPI targets, and I'd have to say they've delivered on all points. On our landing pages, we've seen over a 30% conversion rate increase.<br/> We have a lot of exciting initiatives for the next year and Spiralyze is at the core of a lot of these initiatives.”",
    companyLogo: "Servicemax_logo.png",
    videoID: "9LBXrVZsiXA",
    videoLength: "4 min",
    logoAlt: "ServiceMax",
    url: "/testimonials#servicemax",
  },
  // {
  //   slideImage: "Ideal_Image_Brian_Stetter.png",
  //   personName: "Brian Stetter",
  //   personPosition: "VP of Digital Marketing",
  //   smallFeedback:
  //     "“Spiralyze's impact has been tremendous. In about 3 months, we improved our laser hair removal landing page conversion rates by over 30% which has an amazing impact on business.”",
  //   companyLogo: "IdealImage_logo.png",
  //   videoID: "m4mn1DZIJBM",
  //   videoLength: "3 min",
  //   logoAlt: "Ideal Image",
  //   url: "/testimonials#idealimage",
  // },
  {
    slideImage: "thumb-02.webp",
    personName: "Cadi Cadlecek",
    personPosition: "Senior Marketing Associate",
    smallFeedback:
      "“Spiralyze reformatted our web pages, making them user friendly and highly conversional. We loved working with their team, they were so responsive and easy to work with! If things continue how they are - it's gonna have an amazing impact on our business.”",
    companyLogo: "record360logo.png",
    videoID: "y4Onk1uFll0",
    videoLength: "2 min",
    logoAlt: "Record360",
    url: "/testimonials#record360",
  },
  {
    slideImage: "thumb-04.webp",
    personName: "Michael Freeman",
    personPosition: "VP of Demand Marketing",
    smallFeedback:
      "“Working with Spiralyze, for Adaptive Insights, has been fantastic. They have truly been an extension of our team, they have helped us improve our conversion rates, we have driven more leads, we have beaten plan, and we have helped build a lot of pipeline.”",
    companyLogo: "Workday_logo.png",
    videoID: "2uQsuv_1tX0",
    videoLength: "2 min",
    logoAlt: "Workday",
    url: "/testimonials#workday",
  },
  {
    slideImage: "thumb-05.webp",
    smallFeedback:
      "“If we even improve conversion on our page by 1% or 2%, it would pay for the Spiralyze contract. Most of the tests we've run have improved conversion rates by 20-50% and they have tests mapped out for the rest of the year. It is not even close to what we could've done on our own and is significantly better.”",
    personName: "Matt Boyce",
    personPosition: "Sr. Director of Demand Generation",
    companyLogo: "Podium_logo.png",
    videoID: "G-55Zm8r57s",
    videoLength: "4 min",
    logoAlt: "Podium",
    url: "/testimonials#podium",
  },
  {
    slideImage: "thumb-14.webp",
    smallFeedback:
      "“Spiralyze really jumpstarted the whole website testing program and process for us. They increased exponentially our qualified pro-conversions. More importantly, they acted as an extension of our team.”",
    personName: "Bill Carney",
    personPosition: "Director of Marketing",
    companyLogo: "RStudio_logo.png",
    videoID: "8RnNZpui6vw",
    videoLength: "2 min",
    logoAlt: "RStudio",
    url: "/testimonials#rstudio",
  },
  {
    slideImage: "thumb-06.webp",
    smallFeedback:
      "“Spiralyze helps us turn thousands of website visitors into leads for our sales force. They have tremendous experience and great suggestions.”",
    personName: "Jason Yang",
    personPosition: "VP of Digital Marketing",
    companyLogo: "Five9_logo.png",
    videoID: "YdkdALKm37w",
    videoLength: "2 min",
    logoAlt: "Five9",
    url: "/testimonials#five9",
  },
]
