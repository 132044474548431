import React, { useMemo } from "react"
import "./clientlogos.scss"
import Container from "../container/container"
import Image from "../../components/image-component/image-2023"
export default function ClientLogos({ version = "v1" }) {
  const logos = useMemo(() => {
    return [
      {
        name: "Logo_Netflix_2x.png",
        alt: "Netflix",
        pictureTagIndexes: [],
      },
      {
        name: "Logo_Podium_2x.png",
        alt: "Podium",
        pictureTagIndexes: [],
      },
      {
        name: "Logo_NBA_2x.png",
        alt: "NBA",
        pictureTagIndexes: [],
      },
      {
        name: "Logo_Lowes_Companies_2x.png",
        alt: "Lowes Companies",
        pictureTagIndexes: [],
      },
      { name: "Logo_Gusto_2x.png", alt: "Gusto", pictureTagIndexes: [] },
      {
        name: "Logo_Harvard_University_2x.png",
        alt: "Harvard University",
        pictureTagIndexes: [],
      },
      {
        name: "Logo_CrowdStrike_2x.png",
        alt: "CrowdStrike",
        pictureTagIndexes: [],
      },
    ]
  }, [])

  return (
    <Container className={`socialproofwrapper pd-40 ${version}`}>
      <div className={`socialprooflogos`}>
        {logos.map((logo, index) => {
          return (
            <React.Fragment key={index}>
              <Image
                sourceFolder={"homepage"}
                lazyLoad={"false"}
                alt={logo?.alt ?? "Client Logos"}
                fallBackImage={logo?.name}
                imgName={logo?.name}
              />
            </React.Fragment>
          )
        })}
      </div>
    </Container>
  )
}
