import React, { useLayoutEffect, useMemo, useState } from "react"
import "./clientresults.scss"
import Container from "../container/container"
import Image from "../../components/image-component/image-2023"
import Slider from "react-slick"
import Modal from "../../components/modal/modal"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { ArrowIcon, PlayIcon, SlickArrowIcon } from "../icons"
import YouTube from "react-youtube"
import { slides } from "../../utilities/clientsslider"
import { AspectRatio } from "react-aspect-ratio"
import Button from "../button/button"

export default function ClientResults({
  version = "v1",
  background = "gray-bg",
}) {
  const isSSR = typeof document !== "undefined"

  useLayoutEffect(() => {
    const slickPrev = document.querySelectorAll("#slick-prev")
    const slickNext = document.querySelectorAll("#slick-next")
    slickPrev.forEach(el => {
      el.addEventListener("click", () => {
        document.querySelector(".slick-arrow.slick-prev").click()
      })
    })
    slickNext.forEach(el => {
      el.addEventListener("click", () => {
        document.querySelector(".slick-arrow.slick-next").click()
      })
    })
  }, [version])

  const settings = useMemo(() => {
    if (version === "v2") {
      return {
        dots: false,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 3000,
        cssEase: "linear",
        slidesToShow: 7,
        centerMode: true,
        slidesToScroll: 1,
        centerPadding: "4%",
        speed: 250,
        arrows: true,
        responsive: [
          {
            breakpoint: 2500,
            settings: {
              slidesToShow: 6,
              centerPadding: "4%",
            },
          },
          {
            breakpoint: 2000,
            settings: {
              slidesToShow: 5,
              centerPadding: "5%",
            },
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 4,
              centerPadding: "3.5%",
            },
          },
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
              centerPadding: "1%",
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              centerPadding: "6%",
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              vertical: false,
              dots: false,
              centerPadding: "20px 0px",
            },
          },
          {
            breakpoint: 380,
            settings: {
              slidesToShow: 1,
              centerPadding: "20px 0px",
            },
          },
        ],
      }
    }
    return {
      dots: false,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 3000,
      cssEase: "linear",
      slidesToShow: 4,
      slidesToScroll: 1,
      speed: 250,
      arrows: true,
      responsive: [
        {
          breakpoint: 1360,
          settings: {
            slidesToShow: 3,
            centerMode: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            // arrows: false,
            vertical: false,
            dots: false,
            centerMode: true,
            centerPadding: "20px",
            slidesToShow: 1,
          },
        },
      ],
    }
  }, [version])

  const SlickNextPrevArrows = () => {
    return (
      <div className="slick-custom-next-prev">
        <span id="slick-prev">
          {" "}
          <SlickArrowIcon />
        </span>
        <span id="slick-next">
          <SlickArrowIcon next={true} />
        </span>
      </div>
    )
  }

  const ClientCard = ({
    personName,
    smallFeedback,
    logoAlt,
    personPosition,
    companyLogo,
    slideImage,
    videoID,
  }) => {
    const [defaultOpen, setDefaultOpen] = useState(false)
    return (
      <div
        className="client-card"
        onClick={e => {
          const vw = Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
          )
          if (vw < 1024) {
            return
          }
          setDefaultOpen(true)
        }}
      >
        <Image
          sourceFolder={"spiralyze2024website"}
          imgName={slideImage}
          className={"client-pic"}
          cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
          alt={personName}
        />
        <div className="cc-info-hover">
          <Image
            sourceFolder={"testimonialsnew"}
            imgName={companyLogo}
            className={"client-pic"}
            alt={logoAlt}
          />
          <p dangerouslySetInnerHTML={{ __html: smallFeedback }}></p>
        </div>
        <div className="client-card-footer">
          <div className="cc-info">
            <div>
              <h3> {personName} </h3>
              <p> {personPosition} </p>
            </div>
            <div className="play-wrapper">
              <Modal
                modalButton={<PlayIcon />}
                popupClass="youtube-popup"
                defaultOpen={defaultOpen}
                setDefaultOpen={setDefaultOpen}
                modalContent={
                  <AspectRatio ratio="16:16" style={{ margin: "0px auto" }}>
                    <YouTube
                      // opts={{
                      //   playerVars: {
                      //     autoplay: 1,
                      //   },
                      // }}
                      videoId={videoID}
                      onReady={event => {
                        event.target.playVideo()
                      }}
                    />
                  </AspectRatio>
                }
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={background}>
      <Container
        className={`client-results-wrapper header pd-112-top ${version}`}
      >
        <div className={`client-results-header ${version}`}>
          <div>
            <h2> Client Results</h2>
          </div>
          <SlickNextPrevArrows />
        </div>
      </Container>
      <div className={`client-results-wrapper pd-112-bottom ${version}`}>
        <Slider className="client-results-card-wrapper" {...settings}>
          {slides?.map((slide, index) => (
            <ClientCard {...slide} key={index} />
          ))}
        </Slider>
        <div className="client-results-cta">
          <Button
            version={version}
            href={`/testimonials/`}
            icon={<ArrowIcon />}
            type={`cta`}
          >
            See All
          </Button>
        </div>
      </div>
    </div>
  )
}
