import React from "react"
import "./neil-social-proof.scss"
import { QuoteIcon } from "../../microcomponents/icons"
export default function NeilSocialProof({
  imageUrl = "",
  lpText = "",
  peopleDesig = "",
}) {
  return (
    <div className="lp-box-wrapper">
      <div className="lp-box-inner">
        {imageUrl && (
          <div className="lp-image">
            <img src={imageUrl} alt="Social Proof" />
          </div>
        )}
        <div className="lp-context">
          <QuoteIcon />
          {lpText && <p className="lp-text">{lpText}</p>}
          {peopleDesig && (
            <span className="lp-people-desig">{peopleDesig}</span>
          )}
        </div>
      </div>
    </div>
  )
}
